
import { Component, Vue, Prop } from "vue-property-decorator";

@Component<ListingCardUpgrade>({})
export default class ListingCardUpgrade extends Vue {
	@Prop({type: Boolean, required: false, default: false})
	card!: boolean;

	get href() {
		return new URL("https://info.listed.inc/professionals");
	}

	onClick(event: MouseEvent) {
		this.$emit("click", event);

		event.preventDefault();

		window.open(this.href, "_blank");
	}
}
