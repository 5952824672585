
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { RootState } from "~/store";

@Component<AppStoreLink>({
	name: "app-store-link",
})
export default class AppStoreLink extends Vue {
	@Getter("agent") sessionAgent!: RootState["assignedAgent"];

	readonly baseDownloadURL: string | null = this.$config.APP_DOWNLOAD_LINK_IOS || null;

	get downloadURL() {
		if (!this.baseDownloadURL) {
			return null;
		}

		const baseDownloadURLAsURL = new URL(this.baseDownloadURL);

		baseDownloadURLAsURL.searchParams.set("pt", "118470456");
		baseDownloadURLAsURL.searchParams.set("mt", "8"); // unecessary, but we'll add it

		if (this.sessionAgent) {
			baseDownloadURLAsURL.searchParams.set("ct", this.sessionAgent.oid);
		}

		return baseDownloadURLAsURL.toString()
	}

	trackClick(href: string) {
		(this as any).$matomo?.trackLink(href, "link");
	}

	@Prop({type: String, required: true})
	value!: string;
}
