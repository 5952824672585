
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

import RelButton from "@/components/button.vue";
import VowTermsOfUse from "@/components/vow-terms-of-use.vue";

import ConnectToProvidingAgentMutation from "@/apollo/ConnectToProvidingAgentMutation.graphql";
import {ConnectToProvidingAgentVariables, ConnectToProvidingAgent as ConnectToProvidingAgentResult } from "@/gql-typings/ConnectToProvidingAgent";
import { AgentByIdQuery_agent_AgentUser as AgentUserNode } from "@/gql-typings/AgentByIdQuery";
import { RootState } from "~/store";

@Component<VowAgreementAgent>({
	components: {
		RelButton,
	}
})
export default class VowAgreementAgent extends Vue {
	@Getter("agent")
	readonly sessionAgent!: RootState["assignedAgent"];

	@Prop({
		type: Object,
		required: true,
	})
	readonly agent!: AgentUserNode;

	@Prop({
		type: String,
		required: true,
	})
	readonly listingId!: any;

	@Prop({
		type: Function,
		required: true,
	})
	readonly onAgree!: typeof Function;

	showTermsOfUse(event: MouseEvent) {
		event.preventDefault();

		const {agent} = this;

		let agreed = false;

		this.$modal.show(VowTermsOfUse, {
			agent,
			onAgree: (_event: any) => {
				agreed = true;
				this.becomeClient();
			},
		}, {
			name: "tou",
			adaptive: true,
			height: "100%",
			width: "100%",
			scrollable: true,
			reset: true,
		}, {
			// close both modals upon not accepting the VOW ToU
			'before-close': () => !agreed && this.$emit("close"),
		});
	}

	async becomeClient() {
		const {agent} = this;

		await this.$apollo.mutate<ConnectToProvidingAgentResult, ConnectToProvidingAgentVariables>({
			mutation: ConnectToProvidingAgentMutation,
			variables: {
				input: {
					desiredAgentId: agent.id
				}
			},
		});

		// since access to listings have changed, the easiest way to invalidate all data (listing or otherwise) is to reset the cache
		// this re-fetches active (visible?) queries, which is nice
		await this.$apollo.getClient().resetStore();

		this.onAgree();

		this.$modal.hide("tou");
		this.$emit("close");
	}
}
