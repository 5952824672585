
/// <reference path="../declarations/mapkit/index.d.ts" />
import { Component, Prop, Vue, Watch, Provide, Ref } from "vue-property-decorator";

@Component<MapkitMap>({
	name: "mapkit",
})
export default class MapkitMap extends Vue {
	@Prop({type: Boolean, default: false}) showsUserLocationControl!: boolean;
	@Prop({type: Boolean, default: false}) showsUserLocation!: boolean;
	@Prop({type: String, default: "Adaptive" as mapkit.FeatureVisibility}) showsCompass!: mapkit.FeatureVisibility;
	@Prop({type: Boolean, default: false}) showsPointsOfInterest!: boolean;
	@Prop({type: String}) mapType!: mapkit.Map.MapTypes;
	@Prop({type: Object}) region: mapkit.CoordinateRegion | undefined;
	@Prop({type: Boolean, default: true}) isZoomEnabled!: boolean;
	@Prop({type: Boolean, default: true}) isScrollEnabled!: boolean;
	@Prop({type: Boolean, default: false}) showsMapTypeControl!: boolean;
	@Prop({type: Object}) cameraBoundary!: mapkit.CameraBoundary;
	@Prop({type: Object, default: () => process.client ? new mapkit.Padding(0) : undefined}) padding!: mapkit.Padding;
	@Prop({type: Function}) annotationForCluster!: mapkit.Map["annotationForCluster"];
	@Prop({type: String}) colorScheme!: mapkit.Map.ColorSchemes;

	@Ref("map") readonly mapElement!: Element;

	map: mapkit.Map | null = null;
	mapLoaded: boolean = false;

	@Provide("getMap") provideGetMap = this.getMap;

	@Watch("mapType")
	setMapType(mapType: mapkit.Map.MapTypes) {
		this.map!.mapType = mapType;
	}

	@Watch("colorScheme")
	setColorScheme(val: mapkit.Map.ColorSchemes) {
		this.map!.colorScheme = val;
	}

	@Watch("region")
	setRegion(region?: mapkit.CoordinateRegion, oldRegion?: mapkit.CoordinateRegion) {
		if (!region || (oldRegion && region.equals(oldRegion))) {
			return;
		}

		this.map!.region = region;
	}

	@Watch("showsUserLocationControl")
	setShowUserLocationControl(val: boolean) {
		this.map!.showsUserLocationControl = val;
	}

	@Watch("showsUserLocation")
	setShowsUserLocation(val: boolean) {
		this.map!.showsUserLocation = val;
	}

	@Watch("showsCompass")
	setShowsCompass(val: mapkit.FeatureVisibility) {
		this.map!.showsCompass = val;
	}

	@Watch("isZoomEnabled")
	setIsZoomEnabled(val: boolean) {
		this.map!.isZoomEnabled = val;
	}

	@Watch("isScrollEnabled")
	setIsScrollEnabled(val: boolean) {
		this.map!.isScrollEnabled = val;
	}

	@Watch("showsMapTypeControl")
	setShowsMapTypeControl(val: boolean) {
		this.map!.showsMapTypeControl = val;
	}

	@Watch("padding")
	setPadding(val: mapkit.Padding) {
		this.map!.padding = val;
	}

	@Watch("cameraBoundary")
	setCameraBoundary(val: mapkit.CameraBoundary) {
		this.map!.cameraBoundary = val;
	}

	/*get style() {
		return `width: 100%; height: 100%`
	}

	get width() {
		return this.$parent.clientWidth;
	}

	get height() {
		return this.$parent.clientHeight;
	}*/

	get pointOfInterestFilter(): mapkit.PointOfInterestFilter {
		return this.showsPointsOfInterest ?
			mapkit.PointOfInterestFilter.including([
				mapkit.PointOfInterestCategory.Airport,
				mapkit.PointOfInterestCategory.Beach,
				mapkit.PointOfInterestCategory.FoodMarket,
				mapkit.PointOfInterestCategory.Hospital,
				mapkit.PointOfInterestCategory.Library,
				mapkit.PointOfInterestCategory.Marina,
				mapkit.PointOfInterestCategory.NationalPark,
				mapkit.PointOfInterestCategory.Park,
				mapkit.PointOfInterestCategory.PublicTransport,
				mapkit.PointOfInterestCategory.School,
				mapkit.PointOfInterestCategory.University,
			]) :
			mapkit.filterExcludingAllCategories;
	}

	@Watch("pointOfInterestFilter")
	setPointOfInterestFilter(val: mapkit.PointOfInterestFilter) {
		this.map!.pointOfInterestFilter = val;
	}

	async init() {
		// this.mapkit = await this.deferredMapKitPromise

		const options: mapkit.MapConstructorOptions = {};

		([
			"showsUserLocationControl",
			"showsUserLocation",
			"showsCompass",
			"mapType",
			"region",
			"isZoomEnabled",
			"isScrollEnabled",
			"showsMapTypeControl",
			"padding",
			"colorScheme",
			"pointOfInterestFilter",
		] as ((keyof MapkitMap) & (keyof mapkit.MapConstructorOptions))[])
		.map(key => ({key, value: this[key]}))
		.filter(({value}) => typeof value !== "undefined")
		.forEach(({key, value}) => options[key] = value as any);

		const map = new mapkit.Map(this.mapElement, options);
		this.map = map;

		[
			"user-location-change",
			"region-change-end",
			"long-press",
			"user-location-error"
		].forEach(eventName => map.addEventListener(eventName, (event: any) => this.$emit(eventName, event)));

		const emitClusterSelect = (event: any) => this.$emit("select-cluster", event);
		const emitClusterDeselect = (event: any) => this.$emit("deselect-cluster", event);

		map.annotationForCluster = (clusterAnnotation) => {
			if (this.annotationForCluster) {
				clusterAnnotation = this.annotationForCluster.call(map, clusterAnnotation);
			}

			clusterAnnotation.addEventListener("select", emitClusterSelect);
			clusterAnnotation.addEventListener("deselect", emitClusterDeselect);

			return clusterAnnotation;
		};

		if (this.cameraBoundary) {
			map.cameraBoundary = this.cameraBoundary;
		}

		this.mapLoaded = true;
	}

	async mounted() {
		await this.init();
	}

	beforeDestroy() {
		const {map} = this;

		if (map) {
			this.map = null;
			map.destroy();
		}
	}

	getMap() {
		return this.map || null;
	}
}
