// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/contact-icon_normal_share.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/contact-icon_normal_phone.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../assets/contact-icon_small_phone.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../assets/contact-icon_normal_message.svg");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../assets/contact-icon_small_message.svg");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../assets/contact-icon_small_fax.svg");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../assets/contact-icon_normal_email.svg");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../assets/contact-icon_small_email.svg");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../assets/contact-icon_normal_website.svg");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../assets/contact-icon_small_website.svg");
var ___CSS_LOADER_URL_IMPORT_10___ = require("../assets/icon_super-simple-search_button_medium.svg");
var ___CSS_LOADER_URL_IMPORT_11___ = require("../assets/icon_map-search_button_medium.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".buttons{width:auto;flex:1 0 auto;display:flex;flex-direction:row;justify-content:center;align-items:flex-start;gap:.75em}.buttons.is-grouped .btn{margin-bottom:0}.buttons.horizontal{flex-wrap:wrap;flex-direction:row;justify-content:center}.buttons.vertical{flex-direction:column;align-items:center;justify-content:center}@media screen and (max-width: 480px){.buttons.mobile-vertical{flex-direction:column;align-items:center;justify-content:center}}.icon i{width:100%;height:100%;display:inline-block;vertical-align:-0.2125em;background-size:contain;background-repeat:no-repeat;background-position:center center}i.icon-contact-share{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}i.icon-contact-phone{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}i.icon-contact-phone.small{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}i.icon-contact-message{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}i.icon-contact-message.small{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}i.icon-contact-fax.small{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}i.icon-contact-email{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ")}i.icon-contact-email.small{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ")}i.icon-contact-website{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ")}i.icon-contact-website.small{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ")}.medium i.icon-super-simple-search{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ")}.medium i.icon-map-search{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
