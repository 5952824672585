
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter, State } from "vuex-class";
import { Agent_agent as AgentNode } from "../gql-typings/Agent";

import GetAppLink from "./get-app-link.vue";

@Component<MobileInstallBanner>({
	components: {
		GetAppLink
	},
})
export default class MobileInstallBanner extends Vue {
	@Prop({type: String, required: false})
	value!: string | undefined;

	@Getter("agent")
	agent!: AgentNode;

	@State("browsingSessionId")
	browsingSessionId!: string | null;

	mobileBannerHidden: boolean = false;

	get target(): string {
		let url: URL;

		if (process.client && !window.URL) {
			return window.location.href;
		}

		if (this.value) {
			url = new URL(this.value);
		} else {
			const {HOST_WEB} = this.$config;

			url = new URL(this.$route.fullPath || "/", HOST_WEB);
		}

		if (this.agent) {
			url.searchParams.set("presented-by", this.agent.oid);

			const campaignEncoded = encodeURIComponent(`presented-by=${this.agent.oid}`);

			url.searchParams.set("utm_campaign", campaignEncoded);
		}

		if (this.browsingSessionId) {
			url.searchParams.set("session-id", this.browsingSessionId);
		}

		url.searchParams.set("utm_medium", "get-app");

		return url.toString();
	}
}
