import { render, staticRenderFns } from "./mobile-install-banner.vue?vue&type=template&id=5a3885c9&scoped=true"
import script from "./mobile-install-banner.vue?vue&type=script&lang=ts"
export * from "./mobile-install-banner.vue?vue&type=script&lang=ts"
import style0 from "./mobile-install-banner.vue?vue&type=style&index=0&id=5a3885c9&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a3885c9",
  null
  
)

export default component.exports