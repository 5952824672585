
import { Component, Prop, Vue } from "vue-property-decorator";
import RelButton from "@/components/button.vue";
import { AgentByIdQuery_agent_AgentUser as AgentUserNode } from "@/gql-typings/AgentByIdQuery";

@Component<VowAgreementAgent>({
	components: {
		RelButton,
	}
})
export default class VowAgreementAgent extends Vue {
	@Prop({
		type: Object,
		required: true,
	})
	readonly agent!: AgentUserNode;

	@Prop({
		type: Function,
		required: true,
	})
	readonly onAgree!: typeof Function;

	disabled: boolean = false;

	async onAgreeClick(event: MouseEvent) {
		event.preventDefault();

		this.disabled = true;

		this.onAgree();
	}
}
