
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ClazyLoad from "@/components/universal-clazy-load.vue";
import ShortListingMixin from "./short-listing-mixin.vue";

@Component<ListingCard>({
	components: {
		ClazyLoad,
	},
})
export default class ListingCard extends mixins(ShortListingMixin) {
	@Prop({type: Boolean, required: false, default: false})
	card!: boolean;

	useOptimisticResponse = false;

	onClick(event: MouseEvent) {
		this.$emit("click", event);

		event.preventDefault();

		const {href} = this;

		// fill the cache with whatever data we have on the listing
		// NOTE: this doesn't work in cluster annotations due to how they're constructed (manually)
		this.saveToApolloCache();

		this.$router.push(href);
	}
}
