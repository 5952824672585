var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"range-holder"},[_c('input',{attrs:{"type":"tel","readonly":_vm.disabled,"size":_vm.size},domProps:{"value":_vm.value.min},on:{"change":(event) => {
			_vm.value.min = event.target.value
			_vm.priceRangePartChange()
		}}}),_vm._v(" "),_c('span',[_vm._v("to")]),_vm._v(" "),_c('input',{attrs:{"type":"tel","readonly":_vm.disabled,"size":_vm.size},domProps:{"value":_vm.value.max},on:{"change":(event) => {
			_vm.value.max = event.target.value
			_vm.priceRangePartChange()
		}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }