
import { Component, Prop, Vue } from "vue-property-decorator";

import CreateSavedListingSearchMutation from "@/apollo/CreateSavedListingSearchMutation.graphql";
import ViewerSavedListingSearchesQuery from "@/apollo/ViewerSavedListingSearchesQuery.graphql";

import { ViewerSavedListingSearches_viewer_AgentUser_savedListingSearches as SavedListingSearchesResult, ViewerSavedListingSearches_viewer_AgentUser_savedListingSearches_edges as SavedListingSearchEdge, ViewerSavedListingSearches_viewer_AgentUser_savedListingSearches_edges_node as SavedListingSearchNode } from "../gql-typings/ViewerSavedListingSearches";
import { CreateSavedListingSearchVariables, CreateSavedListingSearch as CreateSavedListingSearchResult } from "@/gql-typings/CreateSavedListingSearch"
import isNotNull from "~/misc/is-not-null";
import savedListingSearchToQuery from "~/misc/saved-listing-search-to-query";
import SavedListingSearchPickerItem from "./saved-listing-search-picker-item.vue";
import { ListingFilters } from "~/misc/interfaces";
import { RootState } from "~/store";
import { Getter } from "vuex-class";

@Component<SavedListingSearchPickerModal>({
	apollo: {
		savedListingSearches: {
			query: ViewerSavedListingSearchesQuery,
			skip() {
				return !(this.isOpen);
			},
			update(data) {
				return data.viewer.savedListingSearches;
			}
		},
	},
	components: {
		SavedListingSearchPickerItem,
	}
})
export default class SavedListingSearchPickerModal extends Vue {
	@Getter("agent") readonly sessionAgent!: RootState["assignedAgent"];

	@Prop({type: String, required: false, default: ""})
	readonly polyline!: string;

	@Prop({type: Object, required: true})
	readonly filters!: ListingFilters;

	@Prop({type: String, required: false, default: ""})
	readonly defaultSearchTitle!: string;

	readonly savedListingSearches!: SavedListingSearchesResult | null;

	isOpen: boolean = false;
	adding: boolean = false;

	get savedListingSearchesEdges(): SavedListingSearchEdge[] | null {
		return this.savedListingSearches?.edges?.filter((edge): edge is NonNullable<typeof edge> => isNotNull(edge)) || null;
	}

	get loading() {
		return this.adding || this.$apollo.loading;
	}

	async beforeOpen() {
		this.isOpen = true;
	}

	async opened() {
		// don't wait
		this.$apollo.queries.savedListingSearches.refetch();
	}

	beforeClose() {
		this.isOpen = false;
	}

	async createNewSavedListingSearch() {
		const title = window.prompt("Saved Search title", this.defaultSearchTitle);

		if (!title) {
			return;
		}

		const {polyline, filters} = this;

		const minMaxValuesToRange = <T>(min: T | null, max: T | null): null | {begin: T | null, end: T | null} => {
			if (min === null && max === null) {
				return null;
			}

			return {
				begin: min,
				end: max,
			};
		};

		const result = await this.$apollo.mutate<CreateSavedListingSearchResult, CreateSavedListingSearchVariables>({
			mutation: CreateSavedListingSearchMutation,
			variables: {
				input: {
					title,

					polyline,

					statuses: filters.statuses,
					transactionTypes: filters.transactionTypes,
					ownershipTypes: filters.ownershipTypes,
					propertyTypes: filters.propertyTypes,
					styleAttachments: filters.styleAttachments,
					daysAtMarketAvailabilityRange: minMaxValuesToRange(filters.daysAtMarketAvailabilityRangeMin, filters.daysAtMarketAvailabilityRangeMax),
					priceRange: minMaxValuesToRange(filters.priceRangeMin, filters.priceRangeMax),
					maintenanceFeeRange: minMaxValuesToRange(filters.maintenanceFeeRangeMin, filters.maintenanceFeeRangeMax),
					bedroomsRange: minMaxValuesToRange(filters.bedroomsRangeMin, filters.bedroomsRangeMax),
					bedroomsPlusRange: minMaxValuesToRange(filters.bedroomsPlusRangeMin, filters.bedroomsPlusRangeMax),
					bathroomsRange: minMaxValuesToRange(filters.bathroomsRangeMax, filters.bathroomsRangeMax),
					kitchensRange: minMaxValuesToRange(filters.kitchensRangeMin, filters.kitchensRangeMax),
					parkingSpacesRange: minMaxValuesToRange(filters.parkingSpacesRangeMin, filters.parkingSpacesRangeMax),
					interiorAreaRange: minMaxValuesToRange(filters.interiorAreaRangeMin, filters.interiorAreaRangeMax),
					lotSizeWidthRange: minMaxValuesToRange(filters.lotSizeWidthRangeMin, filters.lotSizeWidthRangeMax),
					lotSizeDepthRange: minMaxValuesToRange(filters.lotSizeDepthRangeMin, filters.lotSizeDepthRangeMax),
					lotSizeAreaRange: minMaxValuesToRange(filters.lotSizeAreaRangeMin, filters.lotSizeAreaRangeMax),
					storeysRange: minMaxValuesToRange(filters.storeysRangeMin, filters.storeysRangeMax),
					hasWaterfrontAccess: filters.hasWaterfrontAccess,
					hasOpenHouse: filters.hasOpenHouse,
					viewerReactions: filters.viewerReactions,
					feedTypes: filters.feedTypes,
				}
			}
		});

		const {data} = result;

		await this.$apollo.queries.savedListingSearches.refetch();

		if (data) {
			const savedListingSearch = data.createSavedListingSearch.savedListingSearch

			await this.applySavedListingSearch(savedListingSearch);
		}
	}

	async applySavedListingSearch(savedListingSearch: SavedListingSearchNode) {
		const {id: savedListingSearchId} = savedListingSearch;

		if (!savedListingSearchId) {
			return;
		}

		this.$modal.hide("saved-listing-search-picker");

		const query = savedListingSearchToQuery(savedListingSearch);

		this.$router.push({
			path: "/listings",
			query: {
				...query,
				saved_listing_search_id: savedListingSearchId.replace(/=*$/, ""),
				"presented-by": this.sessionAgent?.oid.replace(/[^a-f0-9]/i, ""),
			}
		});
	}

}
