
import { Component, Vue, Prop } from "vue-property-decorator";
import MapkitAnnotation from "@/components/mapkit-annotation.vue";
import ListingAnnotationPin from "@/components/listing-annotation-pin.vue";
import ListingCardUpgrade from "@/components/listing-card-upgrade.vue";
import { ShortListingFragment } from "../gql-typings/ShortListingFragment";

@Component<ListingPremiumUpgradeAnnotation>({
	components: {
		MapkitAnnotation,
		ListingAnnotationPin,
		ListingCardUpgrade,
	}
})
export default class ListingPremiumUpgradeAnnotation extends Vue {
	@Prop({required: true})
	coordinate!: mapkit.Coordinate;

	@Prop({required: true})
	listing!: ShortListingFragment;

	@Prop({required: false, default: () => ({})})
	data!: any;

	@Prop({type: Boolean, required: false, default: false})
	hovered!: boolean;

	@Prop({type: Boolean, required: false, default: false})
	selected!: boolean;

	@Prop({type: Boolean, required: false, default: true})
	enabled!: boolean;

	@Prop({type: Boolean, required: false, default: false})
	small!: boolean;

	@Prop({type: String, required: false, default: "listing"})
	clusteringIdentifier!: string;

	// This is to workaround changes in Mapkit,
	// in which the containing element no longer specifies the selected state as a CSS class/attribute.
	// This approach listens to `select`/`deselect` events on the underlying annotation instance,
	// and applies the relevant CSS class as appropriate
	underlyingSelected: boolean = false;
}
