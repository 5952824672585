
/// <reference path="../declarations/mapkit/index.d.ts" />

import { Component, Prop, Mixins } from "vue-property-decorator";
import MapkitOverlayMixin from "./mapkit-overlay-mixin.vue";

@Component<MapkitPolygonOverlay>({})
export default class MapkitPolygonOverlay extends Mixins(MapkitOverlayMixin) {
	@Prop({type: Array, required: true}) coordinates!: mapkit.Coordinate[] | mapkit.Coordinate[][];

	createMapkitOverlay() {
		return new mapkit.PolygonOverlay(this.coordinates, {
			style: this.styleObject,
			enabled: this.enabled,
			visible: this.visible,
			selected: this.selected,
			data: this.data,
		});
	}
}
