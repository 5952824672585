
import { Component, Vue, Prop } from "vue-property-decorator";

@Component<RelButton>({
	name: "rel-button",
})
export default class RelButton extends Vue {
	@Prop({type: String, required: false})
	href!: string | undefined;

	@Prop({required: false})
	to!: string | object | undefined;

	@Prop({type: Boolean, required: false, default: true})
	prefetch!: boolean;

	@Prop({type: Boolean, required: false})
	replace!: boolean | undefined;

	get type() {
		return this.to ? "nuxt-link" : this.href ? "a" : "button";
	}
}
