
import { Component, Vue, Prop } from "vue-property-decorator";
import ListingAnnotation from "@/components/listing-annotation.vue";
import MissingListingAnnotation from "@/components/missing-listing-annotation.vue";
import ListingPremiumUpgradeAnnotation from "~/components/listing-premium-upgrade-annotation.vue";
import { Getter } from "vuex-class";

type AnnotationSelectEvent = Event & {
	target: mapkit.Annotation,
	type: "select",
}

const scrollToOptions = {
	easing: "ease",
	offset: -80,
	force: true,
	cancelable: false,
	x: false,
	y: true
};

const scrollToDuration = 400;

@Component<ListingAnnotations>({
	components: {
		ListingAnnotation,
		MissingListingAnnotation,
		ListingPremiumUpgradeAnnotation,
	},
})
export default class ListingAnnotations extends Vue {
	@Getter("arePremiumFeaturesEnabled") arePremiumFeaturesEnabled!: boolean;

	// quick and dirty way to only show upgrade card on 'Browse'
	get isOnBrowse() {
		return this.$route.path === "/listings";
	}

	@Prop({type: Array, required: true})
	annotations!: any[];

	@Prop({type: String, required: true, default: ""})
	hoveredListingId!: string;

	@Prop({type: String, required: true, default: ""})
	selectedListingId!: string;

	async onSelect(event: AnnotationSelectEvent) {
		const scrollToEl = `.listings [data-listing-id=id${event.target.data.id}]`;

		// wait for next tick, as the listing card may not be present yet,
		// if coming from a filtered listing list (deselecting a cluster)
		await this.$nextTick();

		(this as any).$scrollTo(scrollToEl,	scrollToDuration, scrollToOptions);
	}
}
