
import { GraphQLError } from "graphql";
import { Component, Mixins, Prop } from "vue-property-decorator";
import ListingWithVowErrorMixin from "./listing-with-vow-error-mixin.vue";
import ListingByIdQuery from "@/apollo/ListingByIdQuery.graphql";

import { ListingByIdVariables, ListingById as ListingByIdResult} from "@/gql-typings/ListingById";

@Component<MissingListingCard>({})
export default class MissingListingCard extends Mixins(ListingWithVowErrorMixin) {
	@Prop({type: Boolean, required: false, default: false})
	card!: boolean;

	@Prop({type: Object, required: false, default: null})
	listingError!: GraphQLError | null;

	get id() {
		const {listingError: error} = this;

		if (error && (
			this.errorIsVOWError(error) ||
			this.errorIsBrokerListListingForbiddenError(error)
		)) {
			return error.extensions.listingId;
		}

		return null;
	}

	get dataListingId() {
		return this.id ? `id${this.id}` : undefined;
	}

	async onVowAgreementAgentClose(_event: any) {
		const {id: listingId} = this;

		if (!listingId) {
			throw new Error("Cannot find listingId");
		}

		const listingResult = await this.$apollo.query<ListingByIdResult, ListingByIdVariables>({
			query: ListingByIdQuery,
			variables: {
				id: listingId,
			},
			fetchPolicy: "network-only",
		});

		const maybeListingNode = listingResult.data.listing;

		const listingNode = maybeListingNode && (
			maybeListingNode.__typename === "ResidentialListing" ||
			maybeListingNode.__typename === "CommercialListing"
		) ? maybeListingNode : null;

		if (!listingNode) {
			throw new Error("Cannot get listing");
		}

		const url = new URL(listingNode.url);

		await this.$router.push(url.pathname);
	}
}
