
/// <reference path="../declarations/mapkit/index.d.ts" />
import { Vue, Component, Prop, Watch, Inject, Ref } from "vue-property-decorator";

@Component
export default class MapkitAnnotationMixin extends Vue {
	@Prop({type: Object, required: true}) coordinate!: mapkit.Coordinate;
	@Prop({type: String}) title!: string;
	@Prop({type: String}) subtitle!: string;
	@Prop({type: Boolean, default: false}) selected!: boolean;
	@Prop({type: String}) clusteringIdentifier!: string | null;
	@Prop({type: String}) accessibilityLabel!: string | null;
	@Prop({type: Boolean, default: true}) animates!: boolean;
	@Prop({type: Boolean, default: false}) draggable!: boolean;
	@Prop({type: Number, default: 1000}) displayPriority!: number;
	@Prop({type: Boolean, default: true}) enabled!: boolean;
	@Prop({type: Object}) anchorOffset!: DOMPointInit;
	@Prop({type: Object}) data!: any;
	@Prop() callout!: string | ((vue: Vue) => mapkit.AnnotationCalloutDelegate) | undefined;

	@Inject("getMap")
	readonly getMap!: (() => mapkit.Map | null);

	@Ref("callout") readonly calloutEl!: Element;

	annotation!: mapkit.Annotation;

	get optionsCallout() {
		if (this.callout !== undefined) {
			const callout: mapkit.AnnotationCalloutDelegate = {};

			if (typeof this.callout === "function") {
				return this.callout(this);
			} else {
				const getSlotNode = () => this.calloutEl;

				switch (this.callout) {
					case "content":
						callout.calloutContentForAnnotation = getSlotNode;
						break;
					case "element":
						callout.calloutElementForAnnotation = getSlotNode;
						break;
					default:
						break;
				}
			}

			return callout;
		}

		return undefined;
	}

	protected get baseAnnotationConstructorOptions(): mapkit.AnnotationConstructorOptions {
		const options: mapkit.AnnotationConstructorOptions = {
			title: this.title,
			subtitle: this.subtitle,
			selected: this.selected,
			draggable: this.draggable,
			animates: this.animates,
			enabled: this.enabled,
			data: this.data,
		};

		if (typeof this.clusteringIdentifier === "string") {
			options.clusteringIdentifier = this.clusteringIdentifier;
		}

		if (typeof this.displayPriority === "number") {
			options.displayPriority = this.displayPriority;
		}

		if (typeof this.anchorOffset === "object") {
			options.anchorOffset = DOMPoint.fromPoint(this.anchorOffset);
		}

		if (typeof this.accessibilityLabel === "string") {
			options.accessibilityLabel = this.accessibilityLabel;
		}

		const callout = this.optionsCallout;

		if (callout) {
			options.callout = callout;
		}

		return options;
	}

	// unclear how to do inheritance with mixins
	get annotationConstructorOptions(): mapkit.AnnotationConstructorOptions {
		return this.baseAnnotationConstructorOptions;
	}

	createMapkitAnnotation(_coordinate: mapkit.Coordinate, _options?: mapkit.AnnotationConstructorOptions): mapkit.Annotation {
		throw new Error("Not implemented on mixin");
	}

	init() {
		if (this.annotation) {
			this.removeSelf();
		}

		const annotation = this.annotation = this.createMapkitAnnotation(this.coordinate, this.annotationConstructorOptions);

		annotation.addEventListener("select", event => this.$emit("select", event));
		annotation.addEventListener("deselect", event => this.$emit("deselect", event));
		annotation.addEventListener("drag-start", event => this.$emit("drag-start", event));
		annotation.addEventListener("dragging", event => this.$emit("dragging", event));
		annotation.addEventListener("drag-end", event => this.$emit("drag-end", event));

		const map = this.getMap();

		if (map) {
			map.addAnnotation(annotation);
		}
	}

	@Watch("title")
	setTitle(title: string) {
		this.annotation.title = title;
	}

	@Watch("subtitle")
	setSubtitle(subtitle: string) {
		this.annotation.subtitle = subtitle;
	}

	@Watch("selected")
	setSelected(selected: boolean) {
		if (this.annotation.selected !== selected) {
			this.annotation.selected = selected;
		}
	}

	@Watch("callout")
	setCallout() {
		this.init();
	}

	@Watch("clusteringIdentifier")
	setClusteringIdentifier(clusteringIdentifier: string | null) {
		this.annotation.clusteringIdentifier = clusteringIdentifier;
	}

	@Watch("animates")
	setAnimates(value: boolean) {
		this.annotation.animates = value;
	}

	@Watch("draggable")
	setDraggable(value: boolean) {
		this.annotation.draggable = value;
	}

	@Watch("enabled")
	setEnabled(value: boolean) {
		this.annotation.enabled = value;
	}

	@Watch("displayPriority")
	setDisplayPriority(value: number) {
		this.annotation.displayPriority = value;
	}

	@Watch("anchorOffset")
	setAnchorOffset(value: DOMPointInit) {
		this.annotation.anchorOffset = DOMPoint.fromPoint(value);
	}

	@Watch("accessibilityLabel")
	setAccessibilityLabel(value: string | null) {
		this.annotation.accessibilityLabel = value;
	}

	mounted() {
		this.init();
	}

	removeSelf() {
		const map = this.getMap();

		if (map) {
			map.removeAnnotation(this.annotation);
		}
	}

	beforeDestroy() {
		this.removeSelf();
	}
}
