
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ListingWithErrors } from "~/misc/interfaces";
import ListingCard from "./listing-card.vue";
import MissingListingCard from "./missing-listing-card.vue";
import ListingCardUpgrade from "./listing-card-upgrade.vue";

@Component<ListingPageListingsSidebar>({
	components: {
		ListingCard,
		MissingListingCard,
		ListingCardUpgrade,
	},
})
export default class ListingPageListingsSidebar extends Vue {
	@Getter("arePremiumFeaturesEnabled") arePremiumFeaturesEnabled!: boolean;

	@Prop({type: Boolean, required: true})
	displayClusters!: boolean;

	@Prop({type: Array, required: false, default: null})
	filteredListings!: ListingWithErrors[];

	isEdgeListing(edge: ListingWithErrors): edge is (ListingWithErrors & Required<Pick<ListingWithErrors, "listing">>) {
		return edge.listing !== null;
	}
}
