import { GraphQLError } from "graphql";
import { ListingsInBoundingBox_listings_edges as ListingEdge } from "@/gql-typings/ListingsInBoundingBox";
import { BrokerListListingForbiddenError, ListingUnavailableError, ListingWithErrors, VowTermsOfUseAgreementRequiredError } from "./interfaces";
import { errorIsVowTermsOfUseAgreementRequiredError } from "./graphql-error-is-vow-tou-error";
import isNotNull from "./is-not-null";
import { errorIsListingUnavailableError } from "./graphql-error-is-listing-unavailable-error";
import { errorIsBrokerListListingForbiddenError } from "./graphql-error-is-broker-list-forbidden-error";

interface Result {
	listings: {
		edges: ReadonlyArray<ListingEdge | null> | null
	};
	errors?: ReadonlyArray<GraphQLError>;
};

const cachedErrorsForListingEdges = new WeakMap<ListingEdge, GraphQLError[]>();

type GQLListingError = VowTermsOfUseAgreementRequiredError | ListingUnavailableError;

type GQLListingLatLonError = VowTermsOfUseAgreementRequiredError | BrokerListListingForbiddenError;

const errorIsListingError = (e: any): e is GQLListingError => (
	errorIsVowTermsOfUseAgreementRequiredError(e) ||
	errorIsListingUnavailableError(e) ||
	errorIsBrokerListListingForbiddenError(e)
);

const groupListingConnectionQueryResultDataAndErrors = (result: Result, queryRootPath: string[] = ["listings"]): ListingWithErrors[] => {
	const {listings, errors} = result;
	const edges = listings?.edges!;

	const errorsForListingEdges = new Map<ListingEdge, GraphQLError[]>();

	// pre-set the arrays
	edges.forEach(edge => {
		if (!edge) {
			return;
		}

		const cachedErrors = cachedErrorsForListingEdges.get(edge);

		errorsForListingEdges.set(edge, cachedErrors || []);
	});

	// match up errors with the listing edges
	errors?.forEach(error => {
		if (!error.path) {
			return;
		}

		// note: this doesn't currently handle index paths:
		const normalizedPath = error.path
		.map((component, index) => {
			return component === queryRootPath[index] ? null : component;
		})
		.filter((component): component is string => component !== null);

		const [edgeComponent, edgeIndexComponent, nodeComponent] = normalizedPath;

		if (!(
			edgeComponent === "edges" &&
			nodeComponent === "node" &&
			typeof edgeIndexComponent === "number"
		)) {
			return;
		}

		const listingEdge = edges[edgeIndexComponent];

		if (listingEdge) {
			const currentErrors = errorsForListingEdges.get(listingEdge)!
			const currentCachedErrors = cachedErrorsForListingEdges.get(listingEdge);

			if (!(currentCachedErrors && currentErrors === currentCachedErrors)) {
				currentErrors.push(error);
			}
		}
	});

	const errorsForListingEdgesAsArray = [...errorsForListingEdges];

	errorsForListingEdgesAsArray.forEach(([edge, errors]) => cachedErrorsForListingEdges.set(edge, errors));

	return errorsForListingEdgesAsArray
	.map(([{node: listing}, errors]): ListingWithErrors | null => {

		const id = (
			listing?.id ??
			(
				errors
				.filter(errorIsListingError)
				.map(e => e.extensions.listingId)
				.find(id => id)
			) ??
			null
		);

		const latLon = (
			listing?.property?.latLon ??
			(
				errors
				.filter((e): e is GQLListingLatLonError => errorIsVowTermsOfUseAgreementRequiredError(e) || errorIsBrokerListListingForbiddenError(e))
				.map(e => e.extensions.latLon)
				.find(latLon => latLon)
			) ??
			null
		)

		return {
			id,
			latLon,
			listing,
			errors
		};
	})
	.filter(isNotNull);
};

export default groupListingConnectionQueryResultDataAndErrors;
