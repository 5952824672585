
import { Component, Vue, Prop, Watch, Model } from "vue-property-decorator";

const modelName = "inputValue";
const eventName = "change";

@Component<CheckboxItem>({
	name: "checkbox-item",
})
export default class CheckboxItem extends Vue {
	@Model(eventName, {
		default: null,
	})
	readonly [modelName]!: any;

	@Prop({required: false})
	readonly value!: any;

	@Prop({type: Boolean, required: false, default: false})
	readonly disabled!: boolean;

	@Prop({type: String, required: false, default: () => `checkbox-item_${Math.random()}`})
	readonly id!: string;

	checkboxInputValue: any = null;

	@Watch("checkboxInputValue")
	onCheckboxInputValueChange(val: any) {
		this.$emit(eventName, val);
	}

	@Watch(modelName, {
		immediate: true,
	})
	[`on${modelName}Change`](val: any) {
		this.checkboxInputValue = val;
	}
}
