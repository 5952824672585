
import { Component, Vue, Prop } from "vue-property-decorator";
import MapkitAnnotation from "@/components/mapkit-annotation.vue";
import MissingListingCard from "@/components/missing-listing-card.vue";
import { GraphQLError } from "graphql";
import { errorIsBrokerListListingForbiddenError } from "~/misc/graphql-error-is-broker-list-forbidden-error";

@Component<MissingListingAnnotation>({
	components: {
		MapkitAnnotation,
		MissingListingCard,
	}
})
export default class MissingListingAnnotation extends Vue {
	@Prop({required: true})
	coordinate!: mapkit.Coordinate;

	@Prop({required: true})
	listingError!: GraphQLError;

	@Prop({required: false, default: () => ({})})
	data!: any;

	@Prop({type: Boolean, required: false, default: false})
	hovered!: boolean;

	@Prop({type: Boolean, required: false, default: false})
	selected!: boolean;

	@Prop({type: Boolean, required: false, default: true})
	enabled!: boolean;

	@Prop({type: Boolean, required: false, default: false})
	small!: boolean;

	@Prop({type: String, required: false, default: "listing"})
	clusteringIdentifier!: string;

	@Prop({type: Number, required: false, default: 750})
	displayPriority!: number;

	// This is to workaround changes in Mapkit,
	// in which the containing element no longer specifies the selected state as a CSS class/attribute.
	// This approach listens to `select`/`deselect` events on the underlying annotation instance,
	// and applies the relevant CSS class as appropriate
	underlyingSelected: boolean = false;

	get isBrokerListError() {
		return errorIsBrokerListListingForbiddenError(this.listingError);
	}
}
