
/// <reference path="../declarations/mapkit/index.d.ts" />
import { Component, Vue } from "vue-property-decorator";
import { Action, State } from "vuex-class";
import { init as initMapkit} from "./vue-mapkit";

@Component<MapkitMap>({
	name: "mapkit-loader",
})
export default class MapkitMap extends Vue {
	@Action markMapkitAsLoaded!: () => Promise<void>;
	@State readonly mapkitLoaded!: boolean;

	async init() {
		await initMapkit(this.$config.MAPKIT_JS_SCRIPT_SRC);

		this.markMapkitAsLoaded();

		this.$emit("loaded", true);
	}

	async mounted() {
		await this.init();
	}
}
