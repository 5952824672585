
import { Component, Vue, Prop, Watch, Model } from "vue-property-decorator";
import { State } from "vuex-class";
import { isEqual } from "lodash";
import CheckboxItem from "@/components/checkbox-item.vue";
import RelButton from "@/components/button.vue";
import NumberRangeInput from "@/components/number-range-input.vue";
import UpperBoundNumberRangeInput from "@/components/upper-bound-number-range-input.vue";
import { ListingTransactionType, ListingOwnershipType, ListingStatus, ResidentialPropertyStyleAttachment, ResidentialPropertyType, ListingFeedType } from "../gql-typings/globalTypes";
import { ListingFilters } from "../misc/interfaces";
import { unit as mathUnit } from "mathjs";
import { RootState } from "~/store";

type ResidentialPropertyStyleAttachmentOrNull = ResidentialPropertyStyleAttachment | null;

const modelName = "inputValue";
const eventName = "change";

const ownershipTypesGroups = {
	FREEHOLD: [ListingOwnershipType.FREEHOLD],
	FREEHOLD_CONDOMINIUM: [ListingOwnershipType.FREEHOLD_CONDOMINIUM, ListingOwnershipType.LEASEHOLD_CONDOMINIUM, ListingOwnershipType.FREEHOLD_STRATA, ListingOwnershipType.LEASEHOLD_STRATA],
	OTHER: [ListingOwnershipType.CO_OPERATIVE, ListingOwnershipType.CO_OWNERSHIP, ListingOwnershipType.LEASEHOLD, ListingOwnershipType.LIFE_LEASE, ListingOwnershipType.OTHER, ListingOwnershipType.TIME_SHARE, ListingOwnershipType.FRACTIONAL, ListingOwnershipType.FIRST_NATIONS_LEASE],
};

const statusTypesGroups = {
	ACTIVE: [ListingStatus.ACTIVE, ListingStatus.ACTIVE_UNDER_CONTRACT],
	CLOSED: [ListingStatus.CLOSED, ListingStatus.PENDING],
	DELISTED: [ListingStatus.CANCELLED, ListingStatus.EXPIRED, ListingStatus.HOLD, ListingStatus.WITHDRAWN],
}

const styleAttachmentsGroups = {
	DETACHED: [ResidentialPropertyStyleAttachment.DETACHED],
	SEMI_DETACHED: [ResidentialPropertyStyleAttachment.SEMI_DETACHED],
	OTHER: [ResidentialPropertyStyleAttachment.ATTACHED, ResidentialPropertyStyleAttachment.LINK, ResidentialPropertyStyleAttachment.STACKED, null],// handle null, as well
}

const ALL_PROPERTY_TYPES: ReadonlyArray<ResidentialPropertyType> = Object.values(ResidentialPropertyType);

const preferredPropertyTypesSortOrder = [
	ResidentialPropertyType.HOUSE,
	ResidentialPropertyType.APARTMENT,
	ResidentialPropertyType.ROW_TOWNHOUSE,
	ResidentialPropertyType.VACANT_LAND,
	ResidentialPropertyType.DUPLEX,
	ResidentialPropertyType.TRIPLEX,
	ResidentialPropertyType.FOURPLEX,
	ResidentialPropertyType.MULTIPLEX,
	ResidentialPropertyType.PARKING_SPACE,
	ResidentialPropertyType.LOCKER,
	ResidentialPropertyType.COTTAGE,
	ResidentialPropertyType.RECREATIONAL,
	ResidentialPropertyType.FARM,
	ResidentialPropertyType.MIXED,
	ResidentialPropertyType.MODULAR,
	ResidentialPropertyType.MOBILE_TRAILER,
	ResidentialPropertyType.ROOM,
	ResidentialPropertyType.OTHER
]

@Component<ListingSearchFilters>({
	components: {
		RelButton,
		CheckboxItem,
		NumberRangeInput,
		UpperBoundNumberRangeInput,
	}
})
export default class ListingSearchFilters extends Vue {
	@State("viewer")
	viewer!: RootState["viewer"] | null;

	@Model(eventName, {
		required: true,
	})
	readonly [modelName]!: ListingFilters;

	@Prop({
		required: true,
	})
	readonly defaultValue!: ListingFilters;

	value: ListingFilters | null = null;

	get isDefault() {
		return !isEqual(this.value, this.defaultValue);
	}

	get changed() {
		return !isEqual(this.value, this[modelName]);
	}

	expandPropertyTypes = false;

	ownershipTypes: string[] = [];
	feedTypes: string[] = [];
	statuses: string[] = [];
	propertyTypes: ResidentialPropertyType[] = [];
	styleAttachments: string[] = [];

	created() {
		if (this.value) {
			this.onValueFeedTypesChange(this.value.feedTypes, undefined);
			this.onValueStatusTypesChange(this.value.statuses, undefined);
			this.onValueOwnershipTypesChange(this.value.ownershipTypes, undefined);
			this.onValuePropertyTypesChange(this.value.propertyTypes, undefined);
			this.onValueStyleAttachmentsChange(this.value.styleAttachments, undefined);
		}
	}

	mounted() {
		if (document.scrollingElement) {
			document.scrollingElement.scrollTop = 0;
		}
	}

	@Watch("feedTypes")
	async onFeedTypesChange(val: string[], _oldVal: string[]) {
		if (this.value) {
			// use next tick as this can turn on all options
			await this.$nextTick();

			const feedTypes = [...new Set(val.flatMap(i => i.split(",") as ListingFeedType[]))];

			// if empty, select all
			if (!feedTypes.length) {
				feedTypes.push(
					ListingFeedType.MLS,
					ListingFeedType.BROKER_LIST,
				);

				this.value.feedTypes = [];
			}

			feedTypes.sort();

			if (!isEqual(feedTypes, this.value.statuses)) {
				this.value.feedTypes = feedTypes;
			}
		}
	}

	@Watch("value.feedTypes")
	onValueFeedTypesChange(val: ListingFeedType[] | undefined, _oldVal: ListingFeedType[] | undefined) {
		if (!val) {
			return;
		}

		const set = new Set<ListingFeedType>(val);

		const feedTypes = [...set];

		if (!isEqual(feedTypes, this.feedTypes)) {
			this.feedTypes = feedTypes;
		}
	}

	@Watch("statuses")
	async onStatusTypesChange(val: string[], _oldVal: string[]) {
		if (this.value) {
			// use next tick as this can turn on all options
			await this.$nextTick();

			const statusTypes = [...new Set(val.flatMap(i => i.split(",") as ListingStatus[]))];

			// if empty, select all
			if (!statusTypes.length) {
				statusTypes.push(
					...Object.values(statusTypesGroups)
					.filter(group => Boolean(this.viewer || group === statusTypesGroups.ACTIVE))
					.flatMap(i => i)
				);

				this.value.statuses = [];
			}

			statusTypes.sort();

			if (!isEqual(statusTypes, this.value.statuses)) {
				this.value.statuses = statusTypes;
			}
		}
	}

	@Watch("value.statuses")
	onValueStatusTypesChange(val: ListingStatus[] | undefined, _oldVal: ListingStatus[] | undefined) {
		if (!val) {
			return;
		}

		const set = new Set<ListingStatus[]>();

		val.forEach(type => {
			switch (type) {
				case ListingStatus.ACTIVE:
				case ListingStatus.ACTIVE_UNDER_CONTRACT:
					set.add(statusTypesGroups.ACTIVE)
					break;
				case ListingStatus.CLOSED:
				case ListingStatus.PENDING:
					set.add(statusTypesGroups.CLOSED);
					break;
				default:
					set.add(statusTypesGroups.DELISTED);
					break;
			}
		});

		const statusTypes = [...set].map(r => r.join(","));

		if (!isEqual(statusTypes, this.statuses)) {
			this.statuses = statusTypes;
		}
	}

	@Watch("ownershipTypes")
	async onOwnershipTypesChange(val: string[], _oldVal: string[]) {
		if (this.value) {
			// use next tick as this can turn on all options
			await this.$nextTick();

			const ownershipTypes = [...new Set(val.flatMap(i => i.split(",") as ListingOwnershipType[]))];

			// if empty, select all
			if (!ownershipTypes.length) {
				ownershipTypes.push(...Object.values(ownershipTypesGroups).flatMap(i => i));
			}

			ownershipTypes.sort();

			if (!isEqual(ownershipTypes, this.value.ownershipTypes)) {
				this.value.ownershipTypes = ownershipTypes;
			}
		}
	}

	@Watch("value.ownershipTypes")
	onValueOwnershipTypesChange(val: ListingOwnershipType[] | undefined, _oldVal: ListingOwnershipType[] | undefined) {
		if (!val) {
			return;
		}

		const set = new Set<ListingOwnershipType[]>();

		val.forEach(type => {
			switch (type) {
				case ListingOwnershipType.FREEHOLD:
					set.add(ownershipTypesGroups[type]);
					break;
				case ListingOwnershipType.FREEHOLD_CONDOMINIUM:
				case ListingOwnershipType.FREEHOLD_STRATA:
				case ListingOwnershipType.LEASEHOLD_CONDOMINIUM:
				case ListingOwnershipType.LEASEHOLD_STRATA:
					set.add(ownershipTypesGroups.FREEHOLD_CONDOMINIUM);
					break;
				default:
					set.add(ownershipTypesGroups.OTHER);
					break;
			}
		});

		const ownershipTypes = [...set].map(r => r.join(","));

		if (!isEqual(ownershipTypes, this.ownershipTypes)) {
			this.ownershipTypes = ownershipTypes;
		}
	}

	labelForPropertyType(type: ResidentialPropertyType) {
		let prettyType = type.replace(/_/g, " ");

		prettyType = prettyType.charAt(0).toUpperCase() + prettyType.slice(1).toLowerCase();

		switch (type) {
			case ResidentialPropertyType.APARTMENT:
				return "Condo / Apartment";
			case ResidentialPropertyType.ROW_TOWNHOUSE:
				return prettyType.replace(" ", " / ");
			default:
				return prettyType;
		}
	}

	get allPropertyTypesSorted() {
		return ALL_PROPERTY_TYPES
		.slice()
		.sort((a, b) => {
			return (
				preferredPropertyTypesSortOrder.indexOf(a) -
				preferredPropertyTypesSortOrder.indexOf(b)
			);
		})
	}

	get allPropertyTypesOn() {
		const propertyTypesSet = new Set(this.propertyTypes);

		return propertyTypesSet.size === 0 || ALL_PROPERTY_TYPES.every(type => propertyTypesSet.has(type));
	}

	get propertyTypesSummary() {
		const list = this.propertyTypes
		.sort((a, b) => {
			return (
				preferredPropertyTypesSortOrder.indexOf(a) -
				preferredPropertyTypesSortOrder.indexOf(b)
			);
		})
		.map((value) => this.labelForPropertyType(value));

		if ("Intl" in window && "ListFormat" in Intl) {
			return new (Intl as any).ListFormat('en-US', { style: 'long', type: 'conjunction' }).format(list);
		} else {
			return list.join(", ");
		}
	}

	@Watch("propertyTypes")
	async onPropertyTypesChange(val: ResidentialPropertyType[], _oldVal: ResidentialPropertyType[]) {
		if (this.value) {
			// use next tick as this can turn on all options
			await this.$nextTick();

			const propertyTypesSet = new Set(val);
			const allPropertyTypes = ALL_PROPERTY_TYPES.slice();

			let propertyTypes: ResidentialPropertyType[];

			// if all disabled / enabled, deselect all
			if (!propertyTypesSet.size || allPropertyTypes.every(type => propertyTypesSet.has(type))) {
				propertyTypes = allPropertyTypes;
			} else {
				propertyTypes = [...propertyTypesSet];
			}

			propertyTypes.sort();

			if (!isEqual(propertyTypes, this.value.propertyTypes)) {
				this.value.propertyTypes = propertyTypes;
			}
		}
	}

	@Watch("value.propertyTypes")
	onValuePropertyTypesChange(val: ResidentialPropertyType[] | undefined, _oldVal: ResidentialPropertyType[] | undefined) {
		if (!val) {
			return;
		}

		const propertyTypesSet = new Set(val)
		const allPropertyTypes = ALL_PROPERTY_TYPES.slice();

		const propertyTypes = allPropertyTypes.every(type => propertyTypesSet.has(type)) ? [] : val.slice();

		if (!isEqual(propertyTypes, this.propertyTypes)) {
			this.propertyTypes = propertyTypes;
		}
	}

	@Watch("styleAttachments")
	async onStyleAttachmentsChange(val: string[], _oldVal: string[]) {
		if (this.value) {
			// use next tick as this can turn on all options
			await this.$nextTick();

			const styleAttachments = [...new Set(val.flatMap(i => i.split(",").map(a => a === 'null' ? null : a) as (ResidentialPropertyStyleAttachmentOrNull)[]))];

			// if empty, select all
			if (!styleAttachments.length) {
				styleAttachments.push(...Object.values(styleAttachmentsGroups).flatMap(i => i));
			}

			styleAttachments.sort();

			if (!isEqual(styleAttachments, this.value.styleAttachments)) {
				this.value.styleAttachments = styleAttachments;
			}
		}
	}

	@Watch("value.styleAttachments")
	onValueStyleAttachmentsChange(val: ResidentialPropertyStyleAttachmentOrNull[] | undefined, _oldVal: (ResidentialPropertyStyleAttachmentOrNull)[] | undefined) {
		if (!val) {
			return;
		}

		const set = new Set<(ResidentialPropertyStyleAttachmentOrNull)[]>();

		val.forEach(type => {
			switch (type) {
				case ResidentialPropertyStyleAttachment.DETACHED:
				case ResidentialPropertyStyleAttachment.SEMI_DETACHED:
					set.add(styleAttachmentsGroups[type]);
					break;
				default:
					set.add(styleAttachmentsGroups.OTHER);
					break;
			}
		});

		const styleAttachments = [...set].map(r => r.map(a => a === null ? 'null' : a).join(","));

		if (!isEqual(styleAttachments, this.styleAttachments)) {
			this.styleAttachments = styleAttachments;
		}
	}

	applyFilters(_event: MouseEvent) {
		this.$emit(eventName, this.value);
	}

	resetFilters(_event: MouseEvent) {
		this.value = {...this.defaultValue};
	}

	@Watch(modelName, {
		immediate: true,
	})
	[`on${modelName}Change`](val: any) {
		this.value = {...val};
	}

	@Watch("value.queryAvailabilityValues")
	onQueryAvailabilityChange(value: number[], oldValue: number[]) {
		this.$nextTick(() => {
			if (!value.length) {
				if (!this.viewer) {
					value.push(1);
				} else {
					const [lastValue = 0] = (oldValue || []);

					value.push(lastValue ^ 1);
				}
			}
		});
	}

	@Watch("value.transactionTypes")
	onTransactionTypesChange(value: ListingTransactionType[], _old: ListingTransactionType[]) {
		this.$nextTick(() => {
			if (!value.length) {
				value.push(ListingTransactionType.SALE);
			}

			// check if array is sorted
			if (!value.every((a, i, array) => i === 0 ? true : a > array[i - 1])) {
				value.sort(); // else sort
			}
		});
	}

	private rangeGetter(prefix: string) {
		const {value} = this;

		if (!value) {
			return null;
		}

		return {
			min: (value as any)[`${prefix}Min`],
			max: (value as any)[`${prefix}Max`],
		};
	}

	private rangeSetter(prefix: string, newValue: {min: number | null, max: number | null} | null) {
		const {value} = this;

		if (!value || !newValue) {
			return;
		}

		(value as any)[`${prefix}Min`] = newValue.min;
		(value as any)[`${prefix}Max`] = newValue.max;
	}

	get priceRange() {
		return this.rangeGetter("priceRange");
	}

	set priceRange(rangeValue: {min: number | null, max: number | null} | null) {
		this.rangeSetter("priceRange", rangeValue);
	}

	get maintenanceFeeRange() {
		return this.rangeGetter("maintenanceFeeRange");
	}

	set maintenanceFeeRange(rangeValue: {min: number | null, max: number | null} | null) {
		this.rangeSetter("maintenanceFeeRange", rangeValue);
	}

	get bedroomsRange() {
		return this.rangeGetter("bedroomsRange");
	}

	set bedroomsRange(rangeValue: {min: number | null, max: number | null} | null) {
		this.rangeSetter("bedroomsRange", rangeValue);
	}

	get bedroomsPlusRange() {
		return this.rangeGetter("bedroomsPlusRange");
	}

	set bedroomsPlusRange(rangeValue: {min: number | null, max: number | null} | null) {
		this.rangeSetter("bedroomsPlusRange", rangeValue);
	}

	get bathroomsRange() {
		return this.rangeGetter("bathroomsRange");
	}

	set bathroomsRange(rangeValue: {min: number | null, max: number | null} | null) {
		this.rangeSetter("bathroomsRange", rangeValue);
	}

	get kitchensRange() {
		return this.rangeGetter("kitchensRange");
	}

	set kitchensRange(rangeValue: {min: number | null, max: number | null} | null) {
		this.rangeSetter("kitchensRange", rangeValue);
	}

	get parkingSpacesRange() {
		return this.rangeGetter("parkingSpacesRange");
	}

	set parkingSpacesRange(rangeValue: {min: number | null, max: number | null} | null) {
		this.rangeSetter("parkingSpacesRange", rangeValue);
	}

	get daysAtMarketAvailabilityRange() {
		return this.rangeGetter("daysAtMarketAvailabilityRange");
	}

	set daysAtMarketAvailabilityRange(rangeValue: {min: number | null, max: number | null} | null) {
		this.rangeSetter("daysAtMarketAvailabilityRange", rangeValue);
	}

	get storeysRange() {
		return this.rangeGetter("storeysRange");
	}

	set storeysRange(rangeValue: {min: number | null, max: number | null} | null) {
		this.rangeSetter("storeysRange", rangeValue);
	}

	interiorSizeUnit = "sqft";
	lotSizeUnit = "acre";

	private sourceUnitToTargetUnit(input: number, sourceUnit: string, targetUnit: string) {
		const toUnit = mathUnit(input, sourceUnit).to(targetUnit);

		return toUnit.toNumber(targetUnit);
	}

	private rangeFromUnitToUnit(range: {min: number | null, max: number | null} | null, sourceUnit: string, targetUnit: string) {
		if (!range) {
			return null;
		}

		const {min, max} = range;

		return {
			min: typeof min === "number" ? this.sourceUnitToTargetUnit(min, sourceUnit, targetUnit) : null,
			max: typeof max === "number" ? this.sourceUnitToTargetUnit(max, sourceUnit, targetUnit) : null,
		};
	}

	get lotSizeWidthRange() {
		return this.rangeGetter("lotSizeWidthRange");
	}

	set lotSizeWidthRange(rangeValue: {min: number | null, max: number | null} | null) {
		this.rangeSetter("lotSizeWidthRange", rangeValue);
	}

	get lotSizeWidthRangeForChosenUnit() {
		return this.rangeFromUnitToUnit(this.lotSizeWidthRange, "m", "ft");
	}

	set lotSizeWidthRangeForChosenUnit(rangeValue: {min: number | null, max: number | null} | null) {
		this.lotSizeWidthRange = this.rangeFromUnitToUnit(rangeValue, "ft", "m");
	}

	get lotSizeDepthRange() {
		return this.rangeGetter("lotSizeDepthRange");
	}

	set lotSizeDepthRange(rangeValue: {min: number | null, max: number | null} | null) {
		this.rangeSetter("lotSizeDepthRange", rangeValue);
	}

	get lotSizeDepthRangeForChosenUnit() {
		return this.rangeFromUnitToUnit(this.lotSizeDepthRange, "m", "ft");
	}

	set lotSizeDepthRangeForChosenUnit(rangeValue: {min: number | null, max: number | null} | null) {
		this.lotSizeDepthRange = this.rangeFromUnitToUnit(rangeValue, "ft", "m");
	}

	get lotSizeAreaRange() {
		return this.rangeGetter("lotSizeAreaRange");
	}

	set lotSizeAreaRange(rangeValue: {min: number | null, max: number | null} | null) {
		this.rangeSetter("lotSizeAreaRange", rangeValue);
	}

	get lotSizeAreaRangeForChosenUnit() {
		return this.rangeFromUnitToUnit(this.lotSizeAreaRange, "m2", "acre");
	}

	set lotSizeAreaRangeForChosenUnit(rangeValue: {min: number | null, max: number | null} | null) {
		this.lotSizeAreaRange = this.rangeFromUnitToUnit(rangeValue, "acre", "m2");
	}

	get interiorAreaRange() {
		return this.rangeGetter("interiorAreaRange");
	}

	set interiorAreaRange(rangeValue: {min: number | null, max: number | null} | null) {
		this.rangeSetter("interiorAreaRange", rangeValue);
	}

	get interiorAreaRangeForChosenUnit() {
		return this.rangeFromUnitToUnit(this.interiorAreaRange, "m2", "sqft");
	}

	set interiorAreaRangeForChosenUnit(rangeValue: {min: number | null, max: number | null} | null) {
		this.interiorAreaRange = this.rangeFromUnitToUnit(rangeValue, "sqft", "m2");
	}

	get hasWaterfrontAccessTrueOrNullAsFalse(): boolean {
		return this.value?.hasWaterfrontAccess || false;
	}

	set hasWaterfrontAccessTrueOrNullAsFalse(value: boolean) {
		if (this.value) {
			this.value.hasWaterfrontAccess = value || null;
		}
	}

	onDisabledClick(event: MouseEvent) {
		if (!this.viewer) {
			event.preventDefault();

			if (window.confirm("You must be logged in to do this. Would you like to log in?")) {
				this.$router.push({path: "/login", query: {redirect: this.$route.fullPath}});
			}
		}
	}

	moreInformationClick(event: MouseEvent) {
		const {target} = event;

		if (!(target instanceof Element)) {
			return;
		}

		const text = target.getAttribute("data-text");

		if (!text) {
			return;
		}

		window.alert(text.replace(/\t*/g, ""));
	}
}
