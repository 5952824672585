
import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { RootState } from "~/store";

@Component<PlayStoreLink>({
	name: "play-store-link",
})
export default class PlayStoreLink extends Vue {
	@Getter("agent") sessionAgent!: RootState["assignedAgent"];

	readonly baseDownloadURL: string | null = this.$config.APP_DOWNLOAD_LINK_ANDROID || null;

	get downloadURL() {
		if (!this.baseDownloadURL) {
			return null;
		}

		// double-encode URL params
		// ?referrer=utm_source%3Dfoo%26utm_medium%3Dfoo%26utm_campaign%3Dfoo

		const hostname = new URL(this.$config.HOST_WEB).hostname;

		const referrerSearchParams = new URLSearchParams();

		referrerSearchParams.set("utm_source", hostname);
		referrerSearchParams.set("utm_medium", "get-app");

		if (this.sessionAgent) {
			const campaignEncoded = encodeURIComponent(`presented-by=${this.sessionAgent.oid}`);
			referrerSearchParams.set("utm_campaign", campaignEncoded);
		}

		const baseDownloadURLAsURL = new URL(this.baseDownloadURL);

		baseDownloadURLAsURL.searchParams.set("referrer", referrerSearchParams.toString())

		return baseDownloadURLAsURL.toString()
	}

	trackClick(href: string) {
		(this as any).$matomo?.trackLink(href, "link");
	}

	@Prop({type: String, required: true})
	value!: string;
}
