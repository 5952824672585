
import { Component, Vue, Prop, Ref } from "vue-property-decorator";
import ListingCardStatic from "./listing-card-static.vue";
import MapkitLoader from "./mapkit-loader.vue";
import MapkitMap from "./mapkit-map.vue";
import MapkitAnnotation from "./mapkit-annotation.vue";
import MapkitPolygonOverlay from "./mapkit-polygon-overlay.vue";
import MapkitPolylineOverlay from "./mapkit-polyline-overlay.vue";
import ListingAnnotations from "./listing-annotations.vue";
import MissingListingCard from "./missing-listing-card.vue";
import ListingCardUpgrade from "./listing-card-upgrade.vue";
import { Getter } from "vuex-class";

@Component<ListingsMap>({
	components: {
		MapkitLoader,
		MapkitAnnotation,
		MapkitMap,
		MapkitPolygonOverlay,
		MapkitPolylineOverlay,
		ListingAnnotations,
	},
})
export default class ListingsMap extends Vue {
	@Ref("map")	private readonly mapComponent!: MapkitMap | undefined;

	@Getter("arePremiumFeaturesEnabled") arePremiumFeaturesEnabled!: boolean;

	@Prop({type: Object, required: false})
	mapkitRegion!: mapkit.CoordinateRegion[] | null;

	@Prop({type: Boolean, default: false})
	showsUserLocation!: boolean;

	@Prop({type: Object, required: false})
	mapPadding!: mapkit.Padding;

	@Prop({type: Array, required: false})
	polygonOverlayCoordinates!: mapkit.Coordinate[] | null

	@Prop({type: Array, required: false})
	polylineOverlayCoordinates!: mapkit.Coordinate[] | null

	@Prop({type: Object, required: false})
	mapkitCurrentSearchCoordinate!: mapkit.Coordinate | null

	@Prop({type: String, required: false})
	currentSearchCoordinateString!: string | null;

	@Prop({type: String, required: false})
	currentSearchTitle!: string | null;

	@Prop({type: Boolean, required: true})
	displayClusters!: boolean;

	@Prop({type: Array, required: true})
	countClusterAnnotations!: any[] | null;

	@Prop({type: Array, required: true})
	listingAnnotations!: any[] | null;

	@Prop({type: String, required: false})
	hoveredListingId!: string | null;

	@Prop({type: String, required: false})
	selectedListingId!: string | null;

	/**
	 * Mapkit map appearance related props
	 */
	get mapType(): mapkit.Map.MapTypes | null {
		return this.$store.state.mapkitLoaded ? mapkit.Map.MapTypes.Standard : null;
	};

	readonly currentSearchAnnotationOffset: DOMPoint = {x: 0, y: -25} as DOMPoint;
	readonly clusterAnnotationOffset: DOMPoint = {x: 0, y: -26} as DOMPoint;

	get map() {
		return this.mapComponent?.map;
	}

	veronica: string = process.client && getComputedStyle(document.documentElement).getPropertyValue("--veronica").trim() || "";

	overlayStyleProperties: mapkit.StyleProperties = {
		strokeColor: this.veronica,
		lineWidth: 4,
		lineCap: "round",
		lineJoin: "round",
	};

	polylineOverlayStyleProperties: mapkit.StyleProperties = {
		...this.overlayStyleProperties,
		lineDash: [10, 10],
	};

	polygonOverlayStyleProperties: mapkit.StyleProperties = {
		...this.overlayStyleProperties,
		fillColor: this.veronica,
		fillRule: "nonzero",
	};

	private annotationForListingCluster(clusterAnnotation: mapkit.MarkerAnnotation): mapkit.Annotation {
		clusterAnnotation.calloutEnabled = true;

		const listingInstanceMap = new Map<mapkit.Annotation, Vue>();

		const newClusterAnnotation = new mapkit.Annotation(clusterAnnotation.coordinate, (_coordinate, _options) => {
			const annotationElement = document.createElement("div");

			annotationElement.classList.add("listing-cluster-annotation");
			annotationElement.classList.add("annotation");

			const listingIdsClean = clusterAnnotation.memberAnnotations
			// prefix the ID with an alpha phrase, as CSS names cannot start with numbers
			// also remove any `=` used as padding in the base64 ID
			.map(a => `id${a.data.id.replace(/\=/g, "")}`)
			.join(",");

			annotationElement.setAttribute("data-listing-ids", listingIdsClean);

			annotationElement.innerHTML = `<span>${clusterAnnotation.memberAnnotations.length}</span>`;

			return annotationElement;
		});

		newClusterAnnotation.addEventListener("select", _event => newClusterAnnotation.element.classList.add("selected"));
		newClusterAnnotation.addEventListener("deselect", _event => newClusterAnnotation.element.classList.remove("selected"));

		newClusterAnnotation.anchorOffset = DOMPoint.fromPoint(this.clusterAnnotationOffset);

		newClusterAnnotation.callout = {
			calloutContentForAnnotation: (annotation: mapkit.Annotation) => {
				[...listingInstanceMap.values()].forEach(instance => instance.$destroy());

				let edges = this.listingAnnotations;

				const div = document.createElement("div");
				div.classList.add("listing-annotation-content-callout-holder");

				getComputedStyle(div).height;

				annotation.memberAnnotations.forEach(memberAnnotation => {
					const edge = edges?.find(edge => edge.data.id === memberAnnotation.data.id);

					if (!edge) {
						throw new Error("annotation not found for member annotation");
					}

					const {errors, listing} = edge;

					let instance: Vue;

					if (!this.arePremiumFeaturesEnabled && listing?.feedType === 'MLS') {
						instance = new Vue({
							data () {
								return {
									listing: Object.freeze(listing),
								}
							},
							store: this.$store,
							router: this.$router,
							render (h) {
								return h(Vue.extend(ListingCardUpgrade), { props: this.$data })
							},
						})
					} else if (listing) {
						instance = new Vue({
							data () {
								return {
									listing: Object.freeze(listing),
								}
							},
							store: this.$store,
							router: this.$router,
							render (h) {
								return h(Vue.extend(ListingCardStatic), { props: this.$data })
							},
						})
					} else if (errors) {
						instance = new Vue({
							data () {
								return {
									listingError: errors[0],
								}
							},
							store: this.$store,
							router: this.$router,
							render (h) {
								return h(Vue.extend(MissingListingCard), { props: this.$data })
							},
						});
					} else {
						throw new Error();
					}

					instance.$mount(); // pass nothing

					const cardHolder = document.createElement("div");
					cardHolder.classList.add("listing-card-holder");
					cardHolder.appendChild(instance.$el);

					this.$nextTick(() => div.appendChild(cardHolder));

					listingInstanceMap.set(memberAnnotation, instance);
				});

				getComputedStyle(div).height;

				return div;
			}
		};

		return newClusterAnnotation;
	}

	annotationForCluster(clusterAnnotation: mapkit.MarkerAnnotation): mapkit.Annotation {
		const {clusteringIdentifier} = clusterAnnotation;

		if (clusteringIdentifier === "listing") {
			return this.annotationForListingCluster(clusterAnnotation);
		} else {
			throw new Error(`Unexpected clustering identifier ${clusteringIdentifier}`);
		}
	}
}
