
import { Component, Vue, Prop } from "vue-property-decorator";
import ListingCard from "./listing-card.vue";

@Component<ListingCardStatic>({
	components: {
		ListingCard
	}
})
export default class ListingCardStatic extends Vue {
	@Prop({type: Object, required: true})
	listing!: any;

	@Prop({type: Boolean, required: false, default: false})
	card!: boolean;
}
