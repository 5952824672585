
import { Component, Vue, Prop } from "vue-property-decorator";
import { ShortListingFragment } from "../gql-typings/ShortListingFragment";

@Component<ListingAnnotationPin>({})
export default class ListingAnnotationPin extends Vue {
	@Prop({required: true})
	listing!: ShortListingFragment;

	@Prop({type: Boolean, required: false, default: false})
	hovered!: boolean;

	@Prop({type: Boolean, required: false, default: false})
	selected!: boolean;

	@Prop({type: Boolean, required: false, default: false})
	small!: boolean;
}
