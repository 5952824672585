
/// <reference path="../declarations/mapkit/index.d.ts" />
import { Vue, Component, Prop, Watch, Inject } from "vue-property-decorator";

@Component
export default class MapkitOverlayMixin extends Vue {
	@Prop({type: Boolean, default: true}) enabled!: boolean;
	@Prop({type: Boolean, default: true}) visible!: boolean;
	@Prop({type: Boolean, default: false}) selected!: boolean;
	@Prop({type: Object, default: () => ({})}) styleProperties!: mapkit.StyleProperties;
	@Prop({type: Object}) data!: any;

	@Inject("getMap")
	readonly getMap!: (() => mapkit.Map | null);

	get styleObject() {
		return this.styleProperties ? new mapkit.Style(this.styleProperties) : undefined;
	}

	overlay!: mapkit.Overlay;

	createMapkitOverlay(): mapkit.Overlay {
		throw new Error("Not implemented on mixin");
	}

	init() {
		if (this.overlay) {
			this.removeSelf();
		}

		const overlay = this.overlay = this.createMapkitOverlay();

		overlay.addEventListener("select", event => this.$emit("select", event));
		overlay.addEventListener("deselect", event => this.$emit("deselect", event));

		const map = this.getMap();

		if (map) {
			map.addOverlay(overlay);
		}
	}

	@Watch("selected")
	setSelected(selected: boolean) {
		if (this.overlay.selected !== selected) {
			this.overlay.selected = selected;
		}
	}

	@Watch("enabled")
	setEnabled(value: boolean) {
		this.overlay.enabled = value;
	}

	@Watch("styleObject")
	setStyleObject(value: mapkit.Style | undefined) {
		this.overlay.style = value;
	}

	mounted() {
		this.init();
	}

	removeSelf() {
		const map = this.getMap();

		if (map) {
			map.removeOverlay(this.overlay);
		}
	}

	beforeDestroy() {
		this.removeSelf();
	}
}
