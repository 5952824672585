
import { Component, Prop, Vue } from "vue-property-decorator";

import SetSavedListingSearchSubscriptionMutation from "~/apollo/SetSavedListingSearchSubscriptionMutation.graphql";
import UpdateSavedListingSearchMutation from "~/apollo/UpdateSavedListingSearchMutation.graphql";
import DeleteSavedListingSearchMutation from "~/apollo/DeleteSavedListingSearchMutation.graphql";

import { ViewerSavedListingSearches_viewer_AgentUser_savedListingSearches_edges_node as SavedListingSearchNode } from "../gql-typings/ViewerSavedListingSearches";
import { SetSavedListingSearchSubscriptionVariables, SetSavedListingSearchSubscription as SetSavedListingSearchSubscriptionResult } from "../gql-typings/SetSavedListingSearchSubscription";
import { UpdateSavedListingSearchVariables, UpdateSavedListingSearch as UpdateSavedListingSearchResult } from "../gql-typings/UpdateSavedListingSearch";
import { DeleteSavedListingSearchVariables, DeleteSavedListingSearch as DeleteSavedListingSearchResult} from "@/gql-typings/DeleteSavedListingSearch";
import SavedListingSearchMapThumbnail from "./saved-listing-search-map-thumbnail.vue";
import savedListingSearchToQuery from "~/misc/saved-listing-search-to-query";
import { Getter, State } from "vuex-class";
import { RootState } from "~/store";
import { NavigatorShare } from "~/misc/interfaces";
import { ActorEventType } from "~/gql-typings/globalTypes";

@Component<SavedListingSearchPickerItem>({
	components: {
		SavedListingSearchMapThumbnail,
	}
})
export default class SavedListingSearchPickerItem extends Vue {
	@State("canShare") readonly canShare!: boolean;
	@Getter("agent") readonly sessionAgent!: RootState["assignedAgent"];

	@Prop({type: Object, required: true})
	readonly savedListingSearch!: SavedListingSearchNode;

	get location() {
		const {savedListingSearch} = this;
		const query = savedListingSearchToQuery(savedListingSearch);

		return {
			path: "/listings",
			query: {
				...query,
				saved_listing_search_id: savedListingSearch.id.replace(/=*$/, ""),
			}
		}
	}

	get href() {
		const {location} = this;
		const url = new URL(location.path, this.$config.HOST_WEB);

		Object.entries(location.query).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				value.forEach(item => url.searchParams.append(key, item));
			} else {
				url.searchParams.set(key, value);
			}
		});

		return url.toString();
	}

	async applySavedListingSearch() {
		const {savedListingSearch} = this;
		const {id: savedListingSearchId} = savedListingSearch;

		if (!savedListingSearchId) {
			return;
		}

		this.$emit("selected");

		const query = savedListingSearchToQuery(savedListingSearch);

		this.$router.push({
			path: "/listings",
			query: {
				...query,
				saved_listing_search_id: savedListingSearchId.replace(/=*$/, ""),
				"presented-by": this.sessionAgent?.oid.replace(/[^a-f0-9]/i, ""),
			}
		});
	}

	async shareSearch(event: MouseEvent) {
		event.stopPropagation();
		event.preventDefault();

		const {savedListingSearch, sessionAgent, href} = this;

		if (!(savedListingSearch && sessionAgent && href)) {
			return;
		}

		if ((navigator as any).share) {
			let title = savedListingSearch.title;
			title += ` - Presented by ${sessionAgent.name}`;

			const url = href;

			try {
				await ((navigator as any).share as NavigatorShare)({title, url});
			} catch (e) {
				if (e instanceof Error && e.name === "AbortError") {
					// the user cancelled sharing
					(this as any).$matomo?.trackEvent("SavedListingSearch", "Share:Cancel", savedListingSearch.id);
				} else {
					this.$sentry?.captureException(e);
				}

				return;
			}

			(this as any).$matomo?.trackEvent("SavedListingSearch", "Share:Complete", savedListingSearch.id);

			this.$store.dispatch("recordNodeActorEvent", {
				type: ActorEventType.SAVED_LISTING_SEARCH_SHARE,
				resource_id: savedListingSearch.id,
			});
		}
	}

	clickToCopyHref() {
		const {savedListingSearch, href} = this;

		if (!savedListingSearch) {
			return null;
		}

		const textArea = document.createElement("textarea");
		textArea.value = href;

		// Avoid scrolling to bottom
		textArea.style.top = "0";
		textArea.style.left = "0";
		textArea.style.position = "fixed";

		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			document.execCommand("copy");
		} catch (err) {
			return;
		} finally {
			document.body.removeChild(textArea);
		}

		this.$store.dispatch("recordNodeActorEvent", {
			type: ActorEventType.SAVED_LISTING_SEARCH_SHARE,
			resource_id: savedListingSearch.id,
		});

		const timeout = setTimeout(() => {
			this.$modal.hide("dialog");
		}, 3000);

		this.$modal.show("dialog", {
			text: "Copied link to search",
			buttons: [
				{
					title: "Okay",
					default: true,
				},
			],
			"before-close": () => {
				clearTimeout(timeout);
			},
		});
	}

	private isSubmittingSubscriptionChange: boolean = false;

	async toggleSubsciption() {
		if (this.isSubmittingSubscriptionChange) {
			return;
		}

		this.isSubmittingSubscriptionChange = true;

		const id = this.savedListingSearch.id;
		const targetState = !this.savedListingSearch.viewerHasSubscribed;

		const optimisticResponse: SetSavedListingSearchSubscriptionResult & {__typename: string} = {
			__typename: "Mutation",
			setSavedListingSearchSubscription: {
				__typename: "SetSavedListingSearchSubscriptionPayload",
				savedListingSearch: {
					__typename: "SavedListingSearch",
					id,
					viewerHasSubscribed: targetState,
				},
			},
		};

		try {
			await this.$apollo.mutate<SetSavedListingSearchSubscriptionResult, SetSavedListingSearchSubscriptionVariables>({
				mutation: SetSavedListingSearchSubscriptionMutation,
				variables: {
					input: {
						savedListingSearch: id,
						subscribe: targetState,
					}
				},
				optimisticResponse,
			});
		} finally {
			this.isSubmittingSubscriptionChange = false;
		}

		this.$store.dispatch("recordNodeActorEvent", {
			type: targetState ? ActorEventType.SAVED_LISTING_SEARCH_SUBSCRIBE : ActorEventType.SAVED_LISTING_SEARCH_UNSUBSCRIBE,
			resource_id: id,
		});
	}

	async deleteSavedListingSearch() {
		const {savedListingSearch} = this;

		const confirm = window.confirm(`Are you sure you want to delete the Saved Search "${savedListingSearch.title}"?`);

		if (!confirm) {
			return;
		}

		await this.$apollo.mutate<DeleteSavedListingSearchResult, DeleteSavedListingSearchVariables>({
			mutation: DeleteSavedListingSearchMutation,
			variables: {
				input: {
					savedListingSearch: savedListingSearch.id,
				}
			}
		});

		this.$store.dispatch("recordNodeActorEvent", {
			type: ActorEventType.SAVED_LISTING_SEARCH_DELETE,
			resource_id: savedListingSearch.id,
		});

		this.$emit("deleted");
	}

	async editSearchTitle() {
		const {savedListingSearch} = this;

		const title = window.prompt("Rename Saved Search to…", savedListingSearch.title);

		if (!title || savedListingSearch.title === title) {
			return;
		}

		await this.$apollo.mutate<UpdateSavedListingSearchResult, UpdateSavedListingSearchVariables>({
			mutation: UpdateSavedListingSearchMutation,
			variables: {
				input: {
					savedListingSearch: savedListingSearch.id,
					title,
				}
			}
		});

		this.$store.dispatch("recordNodeActorEvent", {
			type: ActorEventType.SAVED_LISTING_SEARCH_RENAME,
			resource_id: savedListingSearch.id,
		});
	}

}
