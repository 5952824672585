import { render, staticRenderFns } from "./vow-terms-of-use.vue?vue&type=template&id=fb32b41a&scoped=true"
import script from "./vow-terms-of-use.vue?vue&type=script&lang=ts"
export * from "./vow-terms-of-use.vue?vue&type=script&lang=ts"
import style0 from "./vow-terms-of-use.vue?vue&type=style&index=0&id=fb32b41a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb32b41a",
  null
  
)

export default component.exports