
import { Component, Vue, Prop } from "vue-property-decorator";
import { VueClazyLoad as ClazyLoad } from "vue-clazy-load";

@Component<UniversalClazyLoad>({
	components: {
		ClazyLoad,
	},
})
export default class UniversalClazyLoad extends Vue {
	@Prop({required: true, type: String})
	readonly src!: string;
}
