
/// <reference path="../declarations/mapkit/index.d.ts" />

import { Component, Prop, Mixins, Ref } from "vue-property-decorator";
import MapkitAnnotationMixin from "./mapkit-annotation-mixin.vue";

@Component<MapkitAnnotation>({
	name: "mapkit-annotation",
})
export default class MapkitAnnotation extends Mixins(MapkitAnnotationMixin) {
	@Prop({type: Object, required: false}) factory!: (coordinate: mapkit.Coordinate, opts: mapkit.AnnotationConstructorOptions) => Element;

	@Ref("annotation") readonly annotationElement!: Element;

	get annotationConstructorOptions(): mapkit.AnnotationConstructorOptions {
		return {
			...this.baseAnnotationConstructorOptions,
		};
	}

	createMapkitAnnotation(
		coordinate: mapkit.Coordinate,
		options?: mapkit.AnnotationConstructorOptions,
	): mapkit.Annotation {
		const factory = this.factory || ((_coordinate: mapkit.Coordinate, _opts: mapkit.AnnotationConstructorOptions) => this.annotationElement);

		return new mapkit.Annotation(coordinate, factory, options);
	}
}
