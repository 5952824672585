
import { Component, Vue, Prop } from "vue-property-decorator";
import AppStoreLink from "./app-store-link.vue";
import PlayStoreLink from "./play-store-link.vue";

@Component<GetAppLink>({
	name: "get-app-link",

	components: {
		AppStoreLink,
		PlayStoreLink,
	}
})
export default class GetAppLink extends Vue {
	get isIOS(): boolean {
		return process.client && (
			(navigator.platform &&
				(
					/iPad|iPhone|iPod/.test(navigator.platform) ||
					(/MacIntel/.test(navigator.platform) && navigator.maxTouchPoints > 2)
				)
			 ) ||
			// /CPU iPhone OS/i.test(navigator.userAgent) || // useful for testing
			false
		)
	}

	get isAndroid(): boolean {
		return process.client && /Android/i.test(navigator.userAgent);
	}

	get tag() {
		return this.isIOS ? "app-store-link" : this.isAndroid ? "play-store-link" : null;
	}

	@Prop({type: String, required: true})
	value!: string;
}
