var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._l((_vm.annotations),function({data, listing, errors, coordinate, clusteringIdentifier}){return [((
				_vm.isOnBrowse &&
				// also add check for if on Browse
				// viewer is an agent user
				!_vm.arePremiumFeaturesEnabled &&
				// and the listing is an MLS listing
				listing && listing.feedType === 'MLS'
			))?_c('listing-premium-upgrade-annotation',_vm._g({key:listing.id,attrs:{"coordinate":coordinate,"data":data,"listing":listing,"clusteringIdentifier":clusteringIdentifier,"hovered":_vm.hoveredListingId === data.id,"selected":_vm.selectedListingId === data.id},on:{"select":_vm.onSelect}},_vm.$listeners)):(listing)?_c('listing-annotation',_vm._g({key:data.oid,attrs:{"coordinate":coordinate,"data":data,"listing":listing,"clusteringIdentifier":clusteringIdentifier,"hovered":_vm.hoveredListingId === data.id,"selected":_vm.selectedListingId === data.id},on:{"select":_vm.onSelect}},_vm.$listeners)):_c('missing-listing-annotation',_vm._g({key:`error_${data.id}`,attrs:{"coordinate":coordinate,"data":data,"listing-error":errors[0],"clusteringIdentifier":clusteringIdentifier,"hovered":_vm.hoveredListingId === data.id,"selected":_vm.selectedListingId === data.id,"displayPriority":clusteringIdentifier === null ? 1000 : 750},on:{"select":_vm.onSelect}},_vm.$listeners))]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }