var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"annotation listing-annotation",class:[
		`status_${_vm.listing.status.toLowerCase()}`,
		{'card-hovered': _vm.hovered},
		{'liked': _vm.listing.viewerReaction === 'LIKE'},
		{'selected': _vm.selected},
		{small: _vm.small},
		{'is-broker-list': _vm.listing.feedType === 'BROKER_LIST'},
	]})
}
var staticRenderFns = []

export { render, staticRenderFns }